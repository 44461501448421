module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"kidsland","start_url":"/","background_color":"#fff","theme_color":"#4ab344","icon":"src/images/favico.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"01e1b104ac7fdeaa827099b05bdac804"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
