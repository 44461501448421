// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-corporate-governance-en-tsx": () => import("./../../../src/pages/corporate-governance.en.tsx" /* webpackChunkName: "component---src-pages-corporate-governance-en-tsx" */),
  "component---src-pages-corporate-governance-tsx": () => import("./../../../src/pages/corporate-governance.tsx" /* webpackChunkName: "component---src-pages-corporate-governance-tsx" */),
  "component---src-pages-financial-reports-en-tsx": () => import("./../../../src/pages/financial-reports.en.tsx" /* webpackChunkName: "component---src-pages-financial-reports-en-tsx" */),
  "component---src-pages-financial-reports-tsx": () => import("./../../../src/pages/financial-reports.tsx" /* webpackChunkName: "component---src-pages-financial-reports-tsx" */),
  "component---src-pages-investors-relations-contact-en-tsx": () => import("./../../../src/pages/investors-relations-contact.en.tsx" /* webpackChunkName: "component---src-pages-investors-relations-contact-en-tsx" */),
  "component---src-pages-investors-relations-contact-tsx": () => import("./../../../src/pages/investors-relations-contact.tsx" /* webpackChunkName: "component---src-pages-investors-relations-contact-tsx" */),
  "component---src-templates-framed-page-template-tsx": () => import("./../../../src/templates/framedPage-template.tsx" /* webpackChunkName: "component---src-templates-framed-page-template-tsx" */),
  "component---src-templates-record-list-tsx": () => import("./../../../src/templates/record-list.tsx" /* webpackChunkName: "component---src-templates-record-list-tsx" */)
}

